@font-face {
  font-family: G T America bold;
  src: url("./assets/Gt-America-Font/GTAmericaBold/GTAmericaBold.otf");
}

@font-face {
  font-family: G T America light;
  src: url("./assets/Gt-America-Font/GTAmericaRegular/GTAmericaRegular.otf");
}

body,
html {
  overflow-x: hidden;
}

body {
  font-family: G T America light !important;
  background-color: #002c3c !important;
}

:root {
  /* FONT SIZES  */
  --font-xsm: 14px;
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 20px;
  --font-xl: 24px;
  --font-2xl: 32px;
  --font-3xl: 36px;
  --font-4xl: 56px;
  /* COLORS VARIABLE */
  --light-black: #212121;
  --white: #ffffff;
  --dark-blue: #002c3c;
  --blue: #011f29;
  --sky-blue: #09f0ff;
  --light-green: #01b635;
}

@media (max-width: 1199.98px) {
  :root {
    --font-md: 16px;
    --font-lg: 16px;
    --font-xl: 20px;
    --font-3xl: 32px;
    --font-4xl: 48px;
  }
}

@media (max-width: 991.98px) {
  :root {
    --font-xl: 18px;
    --font-3xl: 28px;
    --font-4xl: 38px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 15px;
    --font-lg: 20px;
    --font-xl: 16px;
    --font-3xl: 24px;
    --font-4xl: 36px;
  }
  #about .font-lg {
    --font-lg: 14px;
  }
}

@media (max-width: 374.98px) {
  :root {
    --font-3xl: 20px;
    --font-4xl: 30px;
  }
}

.font-xsm {
  font-size: var(--font-xsm);
}

.font-sm {
  font-size: var(--font-sm);
}

.font-md {
  font-size: var(--font-md);
}

.font-lg {
  font-size: var(--font-lg);
}

.font-xl {
  font-size: var(--font-xl);
}

.font-2xl {
  font-size: var(--font-2xl);
}

.font-3xl {
  font-size: var(--font-3xl);
}

.font-4xl {
  font-size: var(--font-4xl);
}

.light-black {
  color: var(--light-black);
}

.white {
  color: var(--white);
}
.blue {
  color: var(--blue);
}

.dark-blue {
  color: var(--dark-blue) !important;
}
.sky-blue {
  color: var(--sky-blue) !important;
}
.light-green {
  color: var(--light-green);
}

/* ================================= COMMON CLASSES  ================================= */
.opacity_07 {
  opacity: 0.7;
}

.heading {
  font-size: var(--font-3xl);
  font-family: G T America bold;
  font-weight: 700;
  text-transform: uppercase;
}

.para {
  font-size: var(--font-lg);
}

.sub_heading {
  font-size: var(--font-xl);
}

.sub_para {
  font-size: var(--font-sm);
}

.fw_bold {
  font-family: G T America bold;
  font-weight: 700;
}

.transition_03s {
  transition: all 0.3s ease-in-out;
}

.scale_img_hover {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.scale_img_hover:hover {
  transform: scale(1.06);
}
.cursor_pointer {
  cursor: pointer;
}
.z_index_2 {
  z-index: 2;
}
/* ================================= HERO SECTION CSS ================================= */

.hero_bg {
  padding-top: 134px;
  /* background: linear-gradient(
    117.31deg,
    #005778 1.59%,
    #00aeef 34.39%,
    #0083b3 67.2%,
    #00aeef 100%
  ); */
}

.hero_imgages {
  z-index: 1;
}

.hero_transeparent_bg_img {
  top: -80px;
  z-index: -1;
}

.hero_circle_after_img {
  margin-top: -160px;
}

.hero_circle_before_img {
  left: 35%;
  bottom: 25%;
}

.hero_arw_1 {
  left: 41%;
  top: 31%;
  z-index: 1;
}

.hero_arw_2 {
  z-index: 1;
  left: 36%;
  bottom: 19%;
}

.hero_btn {
  background-color: var(--light-green);
  padding: 22px 60px;
  border-radius: 30px;
  margin-top: 58px;
}

.hero_btn:hover {
  color: var(--dark-blue);
  background-color: #09f0ff;
}

.hero_card_hover {
  top: 50%;
  left: 50%;
  transform: scale(1) translate(-50%, -50%);
  border-radius: 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hero_card_parrent {
  cursor: pointer;
}

.hero_card_parrent:hover .hero_card_hover {
  opacity: 1;
}

.hero_card_parrent:hover .hero_card_1_img {
  scale: 0.7;
  opacity: 0;
}

.about_video {
  height: 600px !important;
}

.custom_btn_height {
  padding: 24px 77px !important;
}
.fixed_navbar {
  transition: all 0.3s ease-in-out;
}
.click_me {
  animation: wiggle 6s infinite ease;
}

@keyframes wiggle {
  30% {
    transform: scale(1);
  }

  40%,
  60% {
    transform: rotate(-4deg) scale(1);
  }

  50% {
    transform: rotate(4deg) scale(1);
  }

  70% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/* =================================  CSS ================================= */
.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.5 !important;
}
.modal.show .modal-dialog {
  max-width: 500px;
  border-radius: 10px !important;
}
.html5-video-player {
  border-radius: 20px !important;
}
.modal-content {
  background: var(--dark-blue) !important;
  border: 0 !important;
  background-repeat: no-repeat !important;
  background-size: 10% !important;
  background-position: center !important;
}
.modal-open {
  padding-right: 0px !important;
}
.modal {
  padding-right: 0px !important;
}
.modal-header {
  border-bottom: 0 !important;
  background: transparent !important;
}
.btn-close {
  margin-bottom: auto !important;
}
/* ================================= MODULE SLIDER CSS ================================= */

.module_main_img {
  border-radius: 20px;
}
.module_hover_card {
  background: linear-gradient(
    180deg,
    #3e6283 0%,
    rgba(62, 98, 131, 0.75) 99.99%
  );
  border-radius: 20px;
  padding: 31px;
  transform: rotatey(-180deg);
  cursor: pointer;
  padding-right: 10px;
  overflow: hidden;
}
.module_hover_card::-webkit-scrollbar {
  display: none;
}
.module_card_heading {
  max-width: 180px;
  margin: auto;
}
.module_card_top_heading {
  min-height: 100px;
  z-index: 1;
  backdrop-filter: blur(11px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.module_card_para {
  height: 98%;
  overflow-y: scroll;
  margin-top: 50px;
}
.module_card_para::-webkit-scrollbar {
  display: none;
}

/* .module_card_para::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.module_card_para::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.module_card_para::-webkit-scrollbar-thumb {
  background: #0083b389;
  opacity: 0.7;
  border-radius: 10px;
}
.module_card_para::-webkit-scrollbar-thumb:hover {
  background: #0083b3;
}
:focus-visible {
  outline: 0 !important;
}*/
.module_card:hover .module_hover_card {
  opacity: 1 !important;
  transform: rotatey(0deg);
  z-index: 1;
}
.width250 {
  bottom: 0;
}
.loader-fullscreen {
  overflow: hidden;
  background-color: black;
  z-index: 1000;
}
.loader-fullscreen img {
  max-width: 500px;
}
.modal_play_icon {
  z-index: 2;
}
.modal_play_icon svg {
  transition: all 0.3s ease-in-out;
}
.modal_play_icon svg:hover {
  transform: scale(1.1);
}
@media (min-width: 768px) {
  .hero_bg {
    padding-bottom: 360px;
    margin-bottom: 230px;
  }

  .hero_card {
    padding: 25px 50px !important;
    background: var(--blue);
    box-shadow: -30px 30px 100px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -198px;
  }
}

@media (max-width: 1199.98px) {
  .about_video {
    height: 450px !important;
  }
  .hero_bg {
    padding-top: 124px;
    padding-bottom: 150px;
  }
  .hero_card {
    width: 90%;
    bottom: -231px;
  }

  .hero_rect_before_img,
  .hero_rect_after_img {
    max-width: 230px;
  }

  .hero_circle_before_img {
    max-width: 130px;
    left: 39%;
  }

  .hero_circle_after_img {
    max-width: 200px;
  }

  .hero_btn {
    margin-top: 30px;
    padding: 15px 40px;
  }

  .hero_transeparent_bg_img {
    max-width: 400px;
  }
  .module_card_top_heading {
    min-height: 80px;
  }
  .module_card_para {
    margin-top: 19px;
  }
  .module_hover_card svg {
    max-width: 50px;
    max-height: 50px;
  }
  .click_hover_module svg {
    max-width: 50px;
  }
}

@media (max-width: 991.98px) {
  .about_video {
    height: 360px !important;
  }
  .hero_card {
    bottom: -147px;
  }

  .hero_bg {
    padding-bottom: 140px;
    margin-bottom: 150px;
    padding-top: 114px;
  }

  .hero_rect_before_img,
  .hero_rect_after_img {
    max-width: 200px;
  }

  .hero_circle_after_img {
    max-width: 130px;
  }

  .hero_arw_1 svg {
    max-width: 70px;
  }

  .hero_arw_1 {
    top: 11%;
    left: 43%;
  }

  .hero_circle_before_img {
    max-width: 80px;
    left: 37%;
    bottom: 30%;
  }

  .hero_transeparent_bg_img {
    max-width: 360px;
  }

  .hero_btn {
    padding: 12px 24px;
  }
  .module_hover_card svg {
    max-width: 40px;
    max-height: 50px;
  }
  .module_section_heading svg {
    max-width: 60px;
  }
  .module_hover_card svg {
    max-width: 30px;
    max-height: 30px;
  }
  .click_hover_module svg {
    max-width: 50px;
  }
}

@media (max-width: 767.98px) {
  .modal_play_icon svg {
    max-width: 50px;
    max-height: 50px;
    position: absolute;
    right: 10px !important;
    z-index: 11111;
  }
  .hero_btn {
    font-size: 16px !important;
    padding: 18px 0;
    max-width: 349px;
    width: 100%;
  }
  .modal.show .modal-dialog {
    max-width: 540px !important;
  }
  .hero_bg {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .hero_card {
    padding: 20px 12px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: -30px 30px 100px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }

  .hero_transeparent_bg_img {
    top: -40px;
  }

  .hero_card_hover {
    max-width: 133px;
  }
  .module_section_heading svg {
    max-width: 50px;
    max-height: 50px;
  }
}

@media (max-width: 575.98px) {
  .testimonyslogo {
    max-width: 70px;
  }
  .testynomys_section .para {
    font-size: 12px;
  }
  .testynomys_section .font-sm {
    font-size: 16px;
  }

  .slider_container {
    margin: auto !important;
  }
  .loader-fullscreen img {
    max-width: 300px;
  }
  .hero_bg {
    padding-top: 94px;
  }
  .hero_card {
    max-width: 95%;
  }

  .hero_card_hover {
    border-radius: 10px;
  }

  .scale_img_hover:hover {
    transform: scale(1.04);
  }
  .hero_btn {
    margin-top: 36px;
  }
  .hero_card_parrent:hover .hero_card_1_img {
    opacity: 1;
    scale: 1;
  }
  .hero_card_parrent:hover .hero_card_hover {
    opacity: 0;
  }

  .hero_transeparent_bg_img {
    top: -45px;
    z-index: -1;
    left: 48%;
    max-width: 325px;
    transform: translatex(-50%);
  }

  .hero_rect_before_img,
  .hero_rect_after_img {
    max-width: 180px;
  }

  .hero_circle_after_img {
    max-width: 140px;
  }

  .hero_circle_before_img {
    max-width: 86px;
    left: 35%;
    bottom: 23%;
  }

  .hero_arw_2 svg {
    max-width: 30px;
  }

  .hero_circle_after_img {
    margin-top: -100px;
  }

  .hero_arw_2 {
    left: 36%;
    bottom: 13%;
  }

  .hero_arw_1 svg {
    max-width: 60px;
  }

  .hero_arw_1 {
    top: 27%;
    left: 41%;
  }
  .module_card_top_heading {
    min-height: 90px;
  }
}

@media (max-width: 374.98px) {
  .hero_rect_before_img,
  .hero_rect_after_img {
    max-width: 140px;
  }

  .hero_circle_before_img {
    max-width: 66px;
    bottom: 25%;
  }

  .hero_circle_after_img {
    max-width: 100px;
  }

  .hero_transeparent_bg_img {
    max-width: 267px;
  }
}

.modal-open {
  overflow: auto !important;
}

.loader-gif {
  height: 320px;
}
.loader-gif img {
  max-width: 100px;
  height: auto;
  width: 100%;
}

.modal {
  background-color: rgba(0, 0, 0, 0.571) !important;
}
.btn-close {
  background-image: url("./assets/images/svg/cross_icon.svg") !important;
  opacity: 1 !important;
}

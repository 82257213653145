/*--------------------------------- DEFINE FOOTER CSS ---------------------------------*/
.footer-link-border {
  border-right: 1px solid rgba(33, 33, 33, 0.2);
  border-left: 1px solid rgba(33, 33, 33, 0.2);
  height: 30px;
  padding-right: 39px;
  padding-left: 39px;
}
.font_18 {
  font-size: 18px;
}
.copyright-text-color {
  color: #f4f6f8;
}
.footer-border-right-side {
  border-right: 1px solid rgba(33, 33, 33, 0.2);
  padding-right: 39px;
}
.footer_bg {
  background-color: #011f29 !important;
  height: 100%;
  width: 100%;
}
.light-black {
  color: var(--light-black) !important;
}
.footer-text-line {
  position: relative;
}
.border_radius_20 {
  border-radius: 40px !important;
}
.footer-text-line::after {
  top: 0;
  position: absolute;
  content: "";
  height: 1px;
  width: 35px;
  top: 50%;
  right: 0px;
  background: var(--white);
  transform: rotate(90deg);
}
.footer_hover {
  transition: all 0.3s ease-in;
}
.footer_hover:hover {
  color: var(--white) !important;
}
.back-top-btn {
  font-size: 12px;
  color: var(--white) !important;
  padding: 18px 12px 7px 13px !important;
  border-radius: 10px !important;
  background: var(--dark-blue);
  border: none !important;
  position: relative;
}
.back-top-btn::after {
  position: absolute;
  z-index: 2;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  content: url("./assets/images/footer/back-top.svg");
}
.text_line {
  position: relative;
}
.text_line::after {
  bottom: -10px;
  position: absolute;
  content: "";
  display: block;
  height: 0px;
  width: 0px;
  background: var(--white);
  transition: width 0.3s;
  left: 50%;
}
.text_line:hover:after {
  position: absolute;
  content: "";
  height: 3.19px !important;
  width: 100% !important;
  left: 50%;
  color: var(--white) !important;
  transform: translateX(-50%);
}
@media (max-width: 991.98px) {
  .footer-text-line::after {
    height: 1px;
    width: 35px;
    top: 50%;
    right: -5px;
  }
  .trainers-img {
    max-width: 180px !important;
  }
  .border-radius50 {
    border-radius: 50% !important;
  }
}
/*------------------------------------------------- DEFINE TRAINERS CSS -------------------------------------------------*/
.play_icon2 {
  right: 12px !important;
}
.play_icon2 {
  top: 27px !important;
  right: 13px !important;
}
.number {
  position: absolute;
  top: -4%;
  left: 13%;
}
.trainer-img {
  border: 3px solid #ffffff;
}
.heading {
  font-size: var(--font-3xl);
  color: var(--white);
}
.trainers-img {
  max-width: 241px;
  cursor: pointer;
}
.mx-width105 {
  max-width: 105px !important;
}
.trainers-img img {
  transition: all 0.3s ease-in-out;
}
.trainers-img img:hover {
  border-radius: 10px;
}
.bg_dark_green {
  background-color: #002c3c;
  color: white;
}
@media (max-width: 767.98px) {
  .trainers-img {
    max-width: 150px !important;
  }
  .footer-link-border {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 575.98px) {
  .trainers-img {
    max-width: 128px !important;
  }
  .border-radius100 {
    border-radius: 100px !important;
  }
  .play_icon {
    right: 10px !important;
  }
  .play_icon2 {
    top: 30px !important;
    right: 10px !important;
  }
}
@media (max-width: 1199.98px) {
  .sub_para {
    font-size: 14px !important;
  }
  .brain-img {
    max-width: 100px !important;
  }
  .max-width66 {
    max-width: 55px !important;
  }
}
/*------------------------------------------------------- DEFINE HEALERS CSS -------------------------------------------------------*/
.min_height610 {
  min-height: 610px !important;
}
.brain-img {
  max-width: 75px !important;
}
.z-index-2 {
  z-index: 2;
}
.z-index-0 {
  z-index: 0;
}
.max-width250 {
  max-width: 350px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -48px;
}
.top_0 {
  top: 0;
}
.navbar-show__hide {
  top: -120px !important;
}
@media (max-width: 991.98px) {
  .order_1 {
    order: 1 !important;
  }
  .order_2 {
    order: 2 !important;
  }
}
/*--------------------------------------------------- DEFINE BONUSES SLIDER CSS ---------------------------------------------------*/

.bonuses-slider-card {
  padding: 20px 0px 20px 20px !important;
  background: #011f29;
  border-radius: 30px;
  cursor: pointer;
}
.card_img_bg {
  box-shadow: -30px 30px 100px 0px #0000001a;
  max-height: 600px;
  min-height: 576px !important;
  max-width: 578px;
  border-radius: 30px;
  display: flex;
  padding: 0px 11px !important;
  background: #009959 !important;
}
.bounses-img {
  max-width: 370px !important;
  margin: 0 auto;
}
.Slider_btn {
  background: #01b635;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 22px 0px;
  font-size: var(--font-lg);
  width: 100%;
  max-width: 496px !important;
  border-radius: 30px;
  border: none !important;
  transition: all 0.3s ease-in;
}
.Slider_btn:hover {
  color: var(--dark-blue) !important;
  outline: none !important;
  background: #09f0ff;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
}
.Slider_btn:hover svg {
  fill: transparent !important;
}
.Slider_btn:hover svg path {
  stroke: #002c3c !important;
}
.buy_now_btn {
  background: #01b635;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 22px 0px;
  color: var(--white) !important;
  font-size: var(--font-lg);
  width: 100%;
  max-width: 496px !important;
  border-radius: 30px;
  border: none !important;
  transition: all 0.3s ease-in;
}
.buy_now_icon {
  top: 50%;
  transform: translateY(-50%);
}
.buy_now_btn:hover {
  color: var(--dark-blue) !important;
  outline: none !important;
  background: #09f0ff !important;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
}
.buy_now_btn:hover svg {
  fill: transparent !important;
}
.buy_now_btn:hover svg path {
  stroke: #002c3c !important;
}
@media (min-width: 1599px) {
  .card_img_bg {
    box-shadow: -30px 30px 70px rgba(0, 0, 0, 0.1);
    max-height: 600px;
    min-height: 600px !important;
    max-width: 420px;
    border-radius: 30px;
    display: flex;
    padding: 0px 11px !important;
  }
}
@media (max-width: 1367.98px) {
  .card_img_bg {
    max-height: 800px;
    min-height: 500px !important;
    max-width: 336px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    padding: 0px 25px !important;
  }
  .mx-width105 {
    max-width: 90px !important;
  }
}
@media (max-width: 1199.98px) {
  .bonuses-slider-card {
    width: 100% !important;
    max-width: none !important;
  }
  .Slider_btn {
    font-size: 16px !important;
    padding: 10px 0px !important ;
    max-width: 360px;
  }
  .card_img_bg {
    padding: 0px 40px !important;
    max-width: 316px;
    min-height: 400px !important ;
    max-height: 400px !important;
    margin: 0 auto;
  }
  .footer-link-border {
    padding-right: 25px;
    padding-left: 25px;
  }
  .footer-border-right-side {
    padding-right: 25px;
  }
}

@media (max-width: 991.98px) {
  .bonuses-slider-card {
    width: 100% !important;
    max-width: none !important;
  }
  .Slider_btn {
    font-size: 16px !important;
    padding: 10px 0px !important ;
    max-width: 360px;
  }
  .card_img_bg {
    padding: 0px 11px !important;
    min-height: 350px !important ;
    max-height: 400px !important;
  }
}
@media (max-width: 767.98px) {
  .card_img_bg {
    padding: 0px 40px !important;
    max-width: 316px;

    margin: 0 auto;
  }
  .bonuses-slider-card {
    box-shadow: -13px 13px 40px 0px #0000001a !important;
    padding: 20px 0px 35px 0px !important;
  }
  .Slider_btn {
    font-size: 14px !important;
    padding: 16px 0 !important;
  }
  .navbar-overlay .Slider_btn {
    font-size: 20px !important;
  }
}
@media (max-width: 575.98px) {
  .card_img_bg {
    min-height: 380px !important ;
  }
  .bonuses-slider-card {
    box-shadow: 0px 0px 5px #98989866 !important;
    padding: 20px 0px 48px 0px !important;
  }
  .footer_bg {
    background: transparent !important;
  }
}
@media (min-width: 475px) and (max-width: 575px) {
  .bounses-slider-padding {
    padding: 3rem !important;
  }
  .w_xs_80 {
    width: 80% !important;
  }
}
@media (min-width: 426px) and (max-width: 474.98px) {
  .bounses-slider-padding {
    padding: 2rem !important;
  }
  .w_xs_80 {
    width: 90% !important;
  }
}
@media (min-width: 390px) and (max-width: 425.98px) {
  .bounses-slider-padding {
    padding: 1rem !important;
  }
}
@media (max-width: 389.98px) {
  .bounses-slider-padding {
    padding: 24px 8px;
  }
}

/*--------------------------------------------------- DEFINE PROGRAMS CSS ---------------------------------------------------*/
.programs-slider-card {
  padding: 20px 10px 20px 20px !important;
  background: #011f29;
  border-radius: 30px;
  cursor: pointer;
}
.programs_card_bg {
  box-shadow: -30px 30px 100px 0px #0000001a;
  max-height: 650px;
  background: #002c3c !important;
  min-height: 610px !important;
  max-width: 478px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  padding: 0px 11px !important;
}
.bounses-img {
  max-width: 370px !important;
  margin: 0 auto;
}

.Slider_btn {
  background: #01b635;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 17px 0px;
  font-size: var(--font-lg);
  width: 100%;
  max-width: 496px !important;
  border-radius: 30px;
  border: none !important;
}
.Slider_btn:hover {
  color: var(--white);
  outline: none !important;
  background: #09f0ff;
  box-shadow: -3px 3px 30px rgba(0, 0, 0, 0.25);
}
.Slider_btn:hover svg {
  fill: transparent !important;
}
.Slider_btn:hover svg path {
  stroke: #002c3c !important;
}
.slick-dots.slick-thumb {
  display: flex !important;
  justify-content: flex-start !important;
}
.programs-slider .slick-dots {
  width: 45%;
}
.slick-dots {
  top: 3px !important;
  left: 0 !important;
  max-height: 20px !important;
}

.slick-dots li {
  height: 6px !important;
}
.slick-dots {
  bottom: 0 !important;
}
.programs-slider .slick-dots.slick-thumb li.slick-active {
  color: var(--dark-blue);
}
.programs-slider .slick-prev:before,
.programs-slider .slick-next:before {
  font-size: 36px !important;
  color: var(--dark-blue) !important;
  width: 50px !important;
  height: 10px !important;
}
.programs-slider .slick-prev,
.programs-slider .slick-next {
  top: 0 !important;
}
.programs-slider .slick-prev {
  left: 8% !important;
  z-index: 10;
}
.programs-slider .slick-prev:before {
  content: url("./assets/images/programs/svg/prev-arrow-sm.svg") !important;
  transition: all 0.1s ease-in;
}
.programs-slider .slick-prev:hover:before {
  content: url("./assets/images/programs/svg/prev-hover-arrow-sm.svg") !important;
  transition: all 0.1s ease-in;
}
.programs-slider .slick-next:before {
  content: url("./assets/images//programs/svg/next-arrow-sm.svg") !important;
}
.programs-slider .slick-next:hover:before {
  content: url("./assets/images//programs/svg/next-hover-arrow-sm.svg") !important;
}
.programs-slider .slick-next {
  left: 35% !important;
  z-index: 10;
}
.programs-slider .slick-dots li {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.programs-slider .slick-dots li button {
  width: 10px !important;
}
.programs-slider .slick-dots li.slick-active button:before {
  background: #09f0ff !important;
  height: 6px !important;
  width: 34.55px !important;
  opacity: 1;
}
.programs-slider .slick-dots li button:before {
  font-size: 30px !important;
  opacity: 1;
  content: "" !important;
  height: 6px !important;
  width: 34.55px;
  height: 6px;
  margin: 0 3px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background: rgba(62, 98, 131, 0.1);
  display: flex !important;
}
.Bounese-slider .slick-dots.slick-thumb li.slick-active {
  color: var(--dark-blue);
}
.Bounese-slider .slick-dots {
  width: 47%;
}
.Bounese-slider .slick-prev,
.Bounese-slider .slick-next {
  top: 0 !important;
}
.Bounese-slider .slick-prev {
  left: 8% !important;
  z-index: 10;
}
.Bounese-slider .slick-next {
  left: 37% !important;
  z-index: 10;
}

/* DEFINE SMALL SCREEN ARROW IMAGES CODE*/
.Bounese-slider .slick-prev:before {
  content: url("./assets/images/programs/svg/prev-arrow-sm.svg") !important;
  transition: all 0.1s ease-in;
}
.Bounese-slider .slick-next:before {
  content: url("./assets/images/programs/svg/next-arrow-sm.svg") !important;
  transition: all 0.1s ease-in;
}
.Bounese-slider .slick-prev:hover:before {
  content: url("./assets/images/programs/svg/prev-hover-arrow-sm.svg") !important;
}
.Bounese-slider .slick-next:hover:before {
  content: url("./assets/images//programs/svg/next-hover-arrow-sm.svg") !important;
}

.Bounese-slider .slick-dots li {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.Bounese-slider .slick-dots li button {
  width: 10px !important;
}
.Bounese-slider .slick-dots li.slick-active button:before {
  background: #09f0ff !important;
  height: 6px !important;
  width: 34.55px !important;
  opacity: 1;
}
.Bounese-slider .slick-dots li button:before {
  font-size: 30px !important;
  opacity: 1;
  content: "" !important;
  height: 6px !important;
  width: 34.55px;
  height: 6px;
  margin: 0 3px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background: rgba(62, 98, 131, 0.1);
  display: flex !important;
}
.z-index-10 {
  z-index: 10;
}
.bg_dark {
  background-color: #011f29;
}
.Line_big {
  top: -5%;
  height: 95%;
}
.line_small {
  height: 70%;
  width: 100%;
  top: 21%;
}
.about-img {
  max-width: 400px;
}
.max-width66 {
  max-width: 66px;
}
@media (min-width: 1599px) {
  .programs_card_bg {
    max-height: 650px;
    min-height: 650px !important;
    max-width: 420px;
    border-radius: 30px;
    display: flex;
    padding: 0px 11px !important;
  }
  .Line_big {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 1400.98px) {
  .mx-width100 {
    max-width: 80px !important;
  }
  .width-xl-100 {
    width: 100%;
  }
  .number {
    left: 13% !important;
  }
  .programs-slider .slick-prev {
    left: 7% !important;
  }
  .programs-slider .slick-next {
    left: 39% !important;
  }
  .programs-slider .slick-dots {
    width: 48%;
  }
  .Bounese-slider .slick-prev {
    left: 7% !important;
  }
  .Bounese-slider .slick-next {
    left: 41% !important;
  }
  .Bounese-slider .slick-dots {
    width: 50%;
  }
  .font-18 {
    font-size: 18px !important;
  }
}
@media (max-width: 1367.98px) {
  .programs_card_bg {
    max-height: 800px;
    min-height: 560px !important;
    max-width: 336px;
    padding: 0px 25px !important;
  }
}
@media (max-width: 1199.98px) {
  .programs-slider-card {
    width: 100% !important;
    max-width: none !important;
  }
  .Slider_btn {
    font-size: 16px !important;
    padding: 10px 0px !important ;
    max-width: 360px;
  }
  .programs_card_bg {
    padding: 0px 40px !important;
    max-width: 316px;
    min-height: 440px !important ;
    max-height: 450px !important;
    margin: 0 auto;
  }
  .number {
    left: 13% !important;
  }
  .programs-slider .slick-prev {
    left: 7% !important;
  }
  .programs-slider .slick-next {
    left: 45% !important;
  }
  .programs-slider .slick-dots {
    width: 54.5% !important;
  }
  .Bounese-slider .slick-prev {
    left: 7% !important;
  }
  .Bounese-slider .slick-next {
    left: 47% !important;
  }
  .Bounese-slider .slick-dots {
    width: 56.5% !important;
  }
  .min_height610 {
    min-height: 400px !important;
  }
}
@media (max-width: 991.98px) {
  .bg_dark_green p {
    font-size: 12px;
  }
  .programs-slider-card {
    width: 100% !important;
    max-width: none !important;
  }
  .Slider_btn {
    font-size: 13px !important;
    padding: 10px 0px !important ;
    max-width: 360px;
  }
  .programs_card_bg {
    padding: 0px 11px !important;
    min-height: 400px !important ;
    max-height: 400px !important;
  }
  .programs-slider .slick-next {
    left: 55% !important;
  }
  .programs-slider .slick-dots {
    width: 65% !important;
  }
  .Bounese-slider .slick-next {
    left: 60% !important;
  }
  .Bounese-slider .slick-dots {
    width: 70% !important;
  }
}
@media (max-width: 767.98px) {
  .max-width66 {
    max-width: 50px !important;
  }
  .min_height610 {
    min-height: 100% !important;
  }
  .programs_card_bg {
    padding: 0px 40px !important;
    max-width: 316px;
    margin: 0 auto;
  }
  .programs-slider-card {
    padding: 20px 0px 20px 0px !important;
  }
  .Slider_btn {
    font-size: 14px !important;
    padding: 16px 0 !important;
  }
  .programs-slider .slick-dots {
    width: 95% !important;
  }
  .programs-slider .slick-prev {
    left: 10% !important;
  }
  .programs-slider .slick-next {
    left: 80% !important;
  }
  .Bounese-slider .slick-dots {
    width: 97% !important;
  }
  .Bounese-slider .slick-prev {
    left: 10% !important;
  }
  .Bounese-slider .slick-next {
    left: 82% !important;
  }
  .number {
    position: absolute;
    left: 23% !important  ;
    top: -2%;
  }
  .buy_now_btn {
    max-width: 349px !important;
    padding: 19px 0;
    width: 100%;
    font-size: 16px !important;
  }
}
@media (max-width: 575.98px) {
  .programs_card_bg {
    min-height: 380px !important ;
  }
  .programs-slider-card {
    padding: 20px 0px 48px 0px !important;
  }
  .programs-slider .slick-next {
    left: 90% !important;
  }
  .programs-slider .slick-prev {
    left: 2% !important;
  }
  .programs-slider .slick-dots {
    width: 100% !important;
  }
  .Bounese-slider .slick-next {
    left: 87% !important;
  }
  .Bounese-slider .slick-prev {
    left: 5% !important;
  }
  .Bounese-slider .slick-dots {
    width: 97% !important;
  }
  .number {
    position: absolute;
    left: 18% !important;
    top: -2%;
  }
  .bg_dark {
    background-color: transparent;
  }
  .bg_dark .buy_now_btn {
    padding: 18px;
  }
  .buy_now_btn {
    padding: 16px 0px !important;
    max-width: 350px !important;
  }
  .fs_24 {
    font-size: 24px !important;
  }
  .fs_18 {
    font-size: 18px !important;
  }
  .fs_14 {
    font-size: 14px;
  }
  .fs_20 {
    font-size: 20px;
  }
  .Bounese-slider .slick-prev,
  .Bounese-slider .slick-next {
    top: 3px !important;
  }
  .programs-slider .slick-prev,
  .programs-slider .slick-next {
    top: 3px !important;
  }
  .programs-slider .slick-prev,
  .programs-slider .slick-next {
    top: 0 !important;
  }
}
@media (max-width: 474.98px) {
  .Bounese-slider .slick-dots li button:before {
    height: 5px !important;
    width: 24.55px;
  }
  .Bounese-slider .slick-dots li.slick-active button:before {
    height: 5px !important;
    width: 24.55px !important;
    margin: 0 3px;
  }
  .programs-slider .slick-dots li.slick-active button:before {
    height: 5px !important;
    width: 24.55px !important;
  }
  .programs-slider .slick-dots li button:before {
    height: 5px !important;
    width: 24.55px !important;
  }
  .Bounese-slider .slick-dots li {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
  .programs-slider .slick-dots li {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
  .line_small {
    top: 20%;
    width: 80%;
    height: 75%;
  }
}
@media (min-width: 475px) and (max-width: 575px) {
  .bounses-slider-padding {
    padding: 3rem !important;
  }
  .w_xs_80 {
    width: 80% !important;
  }
  .Bounese-slider .slick-next {
    left: 85% !important;
  }
  .Bounese-slider .slick-prev {
    left: 5% !important;
  }
  .Bounese-slider .slick-dots {
    width: 95% !important;
  }
}
@media (min-width: 426px) and (max-width: 474.98px) {
  .bounses-slider-padding {
    padding: 2rem !important;
  }
  .w_xs_80 {
    width: 90% !important;
  }
  .number {
    position: absolute;
    left: 21% !important;
    top: -2%;
  }
  .Bounese-slider .slick-next {
    left: 84% !important;
  }
  .Bounese-slider .slick-prev {
    left: 4% !important;
  }
  .Bounese-slider .slick-dots {
    width: 95% !important;
  }
  .programs-slider .slick-prev {
    left: 4% !important;
  }
  .programs-slider .slick-dots {
    width: 98% !important;
  }
  .programs-slider .slick-next {
    left: 84% !important;
  }
}
@media (min-width: 390px) and (max-width: 425.98px) {
  .bounses-slider-padding {
    padding: 1rem !important;
  }
}

@media (max-width: 449.98px) {
  .max-width250 {
    bottom: 4%;
    max-width: 100%;
    left: 0;
    transform: translateX(0);
  }
}
@media (max-width: 420.98px) {
  .footer-border-right-side {
    border-right: none;
    padding-right: 0px;
  }
  .programs-slider .slick-prev {
    left: 2% !important;
  }
  .programs-slider .slick-dots {
    width: 96% !important;
  }
  .programs-slider .slick-next {
    left: 86% !important;
  }
  .Bounese-slider .slick-prev {
    left: 2% !important;
  }
  .Bounese-slider .slick-dots {
    width: 98% !important;
  }
  .Bounese-slider .slick-next {
    left: 88% !important;
  }
  .number {
    position: absolute;
    left: 16% !important;
    top: -2%;
  }
}
@media (max-width: 389.98px) {
  .programs-slider .slick-prev {
    left: 3% !important;
  }
  .programs-slider .slick-dots {
    width: 96% !important;
  }
  .programs-slider .slick-next {
    left: 87% !important;
  }
}
@media (min-width: 576px) {
  .p_sm_custom {
    padding: 54px 7.5%;
  }
  .max-height-143 {
    max-height: 143px !important;
  }
  .Bounese-slider .slick-prev:before {
    content: url("./assets/images/programs/svg/prev-arrow.svg") !important;
    transition: all 0.1s ease-in;
  }
  .Bounese-slider .slick-next:before {
    content: url("./assets/images/programs/svg/next-arrow.svg") !important;
    transition: all 0.1s ease-in;
  }
  .Bounese-slider .slick-prev:hover:before {
    content: url("./assets/images/programs/svg/prev-hover-arrow.svg") !important;
  }
  .Bounese-slider .slick-next:hover:before {
    content: url("./assets/images//programs/svg/next-hover-arrow.svg") !important;
  }
  .programs-slider .slick-prev:before {
    content: url("./assets/images/programs/svg/prev-arrow.svg") !important;
    transition: all 0.1s ease-in;
  }
  .programs-slider .slick-prev:hover:before {
    content: url("./assets/images/programs/svg/prev-hover-arrow.svg") !important;
    transition: all 0.1s ease-in;
  }
  .programs-slider .slick-next:before {
    content: url("./assets/images//programs/svg/next-arrow.svg") !important;
  }
  .programs-slider .slick-next:hover:before {
    content: url("./assets/images//programs/svg/next-hover-arrow.svg") !important;
  }
}
/* DEFINE MODULE SILDER SECTION */
.module_card .module_text {
  background: -webkit-linear-gradient(
    90deg,
    #ffffff 0%,
    #09f0ff 62.5%,
    #ffffff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  color: #6fbf6b;
}
.module_card:hover .module_text {
  color: transparent;
  background: transparent;
  -webkit-text-stroke: 2px #09f0ff;
}
.modal-open {
  overflow-y: hidden;
}
.font_20 {
  font-size: 20px !important;
}
/* MODULE SLIDER CSS */
.refresh-icon {
  top: 10px !important;
  right: 10px !important;
}

.active-nav-overlay {
  overflow: hidden !important;
}
.fixed_navbar {
  z-index: 100;
  backdrop-filter: blur(10px);
}
.gradient_navbar {
  background: var(--dark-blue);
}
.nav-list-items li::after {
  content: "";
  display: block;
  width: 0%;
  height: 2px;
  background: white;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0%;
  bottom: 0;
  -webkit-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.nav-list-items li:hover::after {
  width: 100%;
}

.navbar-overlay {
  position: fixed;
  width: 100%;
  height: 0%;
  z-index: 10;
  top: -800px;
  left: -800px;
  background: var(--dark-blue);
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0px 0px 100px 0px;
}

.active-nav-overlay .navbar-overlay {
  height: 100vh;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
  overflow-y: hidden !important;
}

.navbar-overlay li {
  position: relative;
  opacity: 0;
  list-style-type: none;
}

.navbar-overlay li::after {
  content: "";
  display: block;
  width: 0%;
  height: 0.5vh;
  background: var(--white);
  left: 50%;
  bottom: 0;
  border-radius: 30px;
}

.navbar-overlay li:hover::after {
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.active-nav-overlay ul li:nth-child(1) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(2) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(3) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(4) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(5) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(6) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.active-nav-overlay ul li:nth-child(7) {
  -webkit-animation: animate_links 0.9s;
  animation: animate_links 0.9s;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes animate_links {
  from {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}

/* HAMBURGER ICON STYLE  */
#nav-icon1 {
  width: 40px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: white;
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 12px;
}

#nav-icon1 span:nth-child(3) {
  top: 24px;
}

.active-nav-overlay #nav-icon1 {
  z-index: 20;
}

.active-nav-overlay #nav-icon1 span:nth-child(1) {
  top: 16px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.active-nav-overlay #nav-icon1 span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.active-nav-overlay #nav-icon1 span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.navbar-link {
  font-size: var(--font-md);
  color: var(--white);
  text-decoration: none;
}
.navbar-link:hover {
  color: var(--white);
  font-family: G T America bold !important;
}

.navbar-link::after {
  position: absolute;
  content: "";
  background-color: var(--color-light-green);
  width: 0%;
  height: 2px;
  bottom: -5px;
  left: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.navbar-link:hover::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
}
@media (min-width: 992px) {
  .navbar-wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 2;
  }
}
@media (max-width: 1199.98px) {
  .navbar-wrapper {
    padding: 12px 0 !important;
  }
  .nav-list-items li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 991.98px) {
  .navbar-wrapper img {
    max-width: 80px;
  }
  .navbar-link {
    font-size: 24px;
  }
}
@media (max-width: 575.98px) {
  .navbar-wrapper img {
    max-width: 65px;
  }
  #nav-icon1 {
    width: 35px;
  }
  #nav-icon1 span:nth-child(2) {
    top: 10px;
  }
  #nav-icon1 span:nth-child(3) {
    top: 20px;
  }
}
